/* eslint-disable react-hooks/exhaustive-deps */
import * as Style from "../styles/HeaderStyles";
import Logo from "../assets/LogoWonderland.png";
import { CiUser } from "react-icons/ci";
import { IoCart } from "react-icons/io5";
import { MdOutlineFavorite } from "react-icons/md";
import { IoIosArrowDown } from "react-icons/io";
import { GiHamburgerMenu } from "react-icons/gi";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useContext, useEffect, useRef, useState } from "react";
import { SiWhatsapp } from "react-icons/si";
import { AuthContext } from "../context/auth/AuthProvider";
import { CartSidebar } from "./CartSidebar";
import { DropDownUser } from "./DropDownUser";
import { NavMenu } from "./NavMenu";
import { NavMenuHamburguer } from "./NavMenuHamburguer";
import { ProductContext } from "../context/product/ProductProvider";
import { CategorieSelectHeader } from "./CategoriSelectHeader";
import { SearchDropDown } from "./SearchDropDown";

export const Header = ({ disabledOnClick = false, searchModal = false }) => {
  const { autenticado, usuarioData, cerrarSesion, usuarioAnonymus } =
    useContext(AuthContext);

  const {
    countCart,
    categoryFilter,
    kindFilter,
    subkindFilter,
    listProductFilter,
  } = useContext(ProductContext);

  const [search, setSearch] = useState("");
  const [loadingSearch, setIsLoadingSearch] = useState(true);
  const [timer, setTimer] = useState(null);
  const [showMenu, setShowMenu] = useState(false);
  const [showDropSearch, setDropSearch] = useState(searchModal);
  const [cartSidebar, setCartSidebar] = useState(false);
  const location = useLocation();
  const { pathname } = location;

  const handleExitSession = () => {
    cerrarSesion();
    usuarioAnonymus(false);
  };

  const SidebarCart = () => {
    setCartSidebar(!cartSidebar);
  };

  const handleSearch = (e) => {
    setDropSearch(searchModal);
    const searchText = e.target.value;
    setSearch(searchText);
    setIsLoadingSearch(true);
    // Comienza el temporizador para esperar 500 ms después de que el usuario termina de escribir
    clearTimeout(timer);
    setTimer(
      setTimeout(() => {
        // Lógica para ejecutar la búsqueda después de 500 ms
        handleSearchApi(searchText);
      }, 1000)
    );
  };

  // const clearSearch = () => {
  //   setSearch("");
  //   handleSearchApi();
  // };

  const handleSearchApi = (searchText) => {
    const regexKind = /^\/category\/([^/]+)\/kind\/([^/]+)$/;
    const regexSubKind =
      /^\/category\/([^/]+)\/kind\/([^/]+)\/subkind\/([^/]+)$/;
    if (pathname.match(regexSubKind)) {
      listProductFilter({
        page_size: 16,
        page: 1,
        category: categoryFilter ? categoryFilter : "pokemon",
        kind: kindFilter ? kindFilter : "",
        subkind: subkindFilter ? subkindFilter : "",
        name: searchText,
      });
      setSearch("");
      return;
    }
    if (pathname.match(regexKind)) {
      listProductFilter({
        page_size: 16,
        page: 1,
        category: categoryFilter ? categoryFilter : "pokemon",
        kind: kindFilter ? kindFilter : "",
        subkind: "",
        name: searchText,
      });
      setSearch("");
      return;
    }
    listProductFilter({
      category: categoryFilter,
      name: searchText,
    }).then(() => {
      setIsLoadingSearch(false);
    });
    document
      .getElementById("sectionToNavigate")
      ?.scrollIntoView({ behavior: "smooth", block: "start" });
    // setSearch("");
  };

  const redirectToWhatsApp = () => {
    const message = encodeURIComponent(
      "¡Hola! Estoy interesado en un producto. 🏬😊"
    );
    window.open(`https://wa.me/961998673?text=${message}`, "_blank");
  };

  const [isOpen, setIsOpen] = useState(false);

  const toggleMenuUser = () => {
    if (!disabledOnClick) {
      setShowMenu(!showMenu);
    }
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const menuRef = useRef(null);
  const userRef = useRef(null);
  const dropdownRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        !userRef.current.contains(event.target)
      ) {
        setShowMenu(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef, userRef]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropSearch(false);
        if (searchModal) {
          setSearch("");
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  useEffect(() => {
    if (cartSidebar) {
      document.body.style.overflow = "hidden"; // Desactivar scroll
    } else {
      document.body.style.overflow = "auto"; // Activar scroll
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [cartSidebar]);

  const navigate = useNavigate();
  const handleRedirectAndScroll = () => {
    navigate("/");
    setTimeout(() => {
      const sectionToNavigate = document.getElementById("sectionToNavigate");
      if (sectionToNavigate) {
        document
          .getElementById("sectionToNavigate")
          ?.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }, 500);
  };

  const handleRedirectProductSearch = (slug) => {
    navigate(`/product/${slug}`);
    setSearch("");
    setIsOpen(false);
  };

  return (
    <Style.HeaderContainer>
      <Style.Header>
        <div>
          <Link to="/">
            <Style.Img src={Logo} alt="logo-wonderland" />
          </Link>
        </div>
        <Style.BoxSearch ref={dropdownRef}>
          <CategorieSelectHeader />
          <Style.SearchInput
            name="text"
            type="text"
            placeholder="Encuentra el producto que estás buscando..."
            onChange={(e) => handleSearch(e)}
            value={search}
            onKeyDown={handleSearch}
            // onKeyUp={(e) => {
            //   if (e.key === "Backspace" && !search) {
            //     clearSearch();
            //   }
            // }}
          />
          <Style.SearchIcon onClick={handleSearchApi} />
          {showDropSearch && search.length > 2 && (
            <SearchDropDown
              search={search}
              loadingSearch={loadingSearch}
              dropdownRef={dropdownRef}
              handleRedirectAndScroll={handleRedirectAndScroll}
              handleRedirectProductSearch={handleRedirectProductSearch}
            />
          )}
        </Style.BoxSearch>

        <Style.Box>
          {autenticado ? (
            <Style.BoxUser>
              <CiUser size={30} color="#555555" />
              <p ref={userRef} className="user-data" onClick={toggleMenuUser}>
                {" "}
                {usuarioData?.first_name.length > 10
                  ? usuarioData.first_name.slice(0, 10) + "..."
                  : usuarioData?.first_name}
                {!disabledOnClick && <IoIosArrowDown />}
              </p>
              {showMenu && (
                <DropDownUser
                  menuRef={menuRef}
                  cerrarSesion={handleExitSession}
                />
              )}
            </Style.BoxUser>
          ) : (
            <Style.BtnLogin to={"/login"}>
              <Style.BoxUser>
                <CiUser size={30} color="#555555" />
                <p>Ingresar / Registrarse</p>
              </Style.BoxUser>
            </Style.BtnLogin>
          )}
          <Style.BoxIconCartFavorite>
            <Style.CartIcon onClick={SidebarCart}>
              <IoCart size={30} />
              {countCart > 0 && <Style.CartCount>{countCart}</Style.CartCount>}
            </Style.CartIcon>

            {false && <MdOutlineFavorite size={30} color="#555555" />}
          </Style.BoxIconCartFavorite>
        </Style.Box>
      </Style.Header>
      <Style.NavArea>
        <NavMenu />
        <Style.ContainerMenuBurguer>
          <Style.BurgerMenuIcon onClick={toggleMenu}>
            <GiHamburgerMenu color="#ffffff" /> <p> Menú</p>
          </Style.BurgerMenuIcon>{" "}
          {isOpen && (
            <NavMenuHamburguer
              handleSearchApi={handleSearchApi}
              showDropSearch={showDropSearch}
              loadingSearch={loadingSearch}
              dropdownRef={dropdownRef}
              handleRedirectAndScroll={handleRedirectAndScroll}
              handleRedirectProductSearch={handleRedirectProductSearch}
              searchMobile={search}
              handleSearch={handleSearch}
              setSearch={setSearch}
            />
          )}
        </Style.ContainerMenuBurguer>

        <Style.WhatsAppBtn onClick={redirectToWhatsApp}>
          <SiWhatsapp size={15} />
          <p>Chatea con nosotros</p>
        </Style.WhatsAppBtn>
      </Style.NavArea>
      <Style.Overlay $activar={cartSidebar} />
      <CartSidebar setCartSidebar={setCartSidebar} cartSidebar={cartSidebar} />
    </Style.HeaderContainer>
  );
};
