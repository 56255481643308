import React, { useContext, useEffect, useState } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import {
  Dropdown,
  SelectCategory,
  SelectCategoryBox,
} from "../styles/HeaderStyles";
import styled from "styled-components";
import { ProductContext } from "../context/product/ProductProvider";
import {
  CategoryHeader,
  CategoryImage,
  CategoryName,
} from "../styles/FilterStyles";

const DropdownContainer = styled.div``;

export const CategorieSelectHeader = () => {
  const { categoryList, changeCategory, categoryFilter } =
    useContext(ProductContext);

  const [isOpenCategory, setIsOpenCategory] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("Pokémon");

  useEffect(() => {
    setSelectedCategory(categoryFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryFilter]);

  const toggleDropdown = () => {
    setIsOpenCategory(!isOpenCategory);
  };

  const handleCategorySelect = (category) => {
    changeCategory({ category: category.slug });
    setSelectedCategory(category.name);
    setIsOpenCategory(false);
  };

  const displayCategory =
    selectedCategory.length > 10
      ? `${selectedCategory.slice(0, 9)}...`
      : selectedCategory;

  return (
    <DropdownContainer>
      <SelectCategory onClick={toggleDropdown}>
        <SelectCategoryBox>
          <span>{displayCategory}</span>
          {isOpenCategory ? (
            <IoIosArrowUp color="#ffffff" />
          ) : (
            <IoIosArrowDown color="#ffffff" />
          )}
        </SelectCategoryBox>
      </SelectCategory>
      <Dropdown $isOpenCategory={isOpenCategory}>
        {categoryList.map((category) => (
          <CategoryHeader
            onClick={() => handleCategorySelect(category)}
            $isSelected={selectedCategory === category.name}
            key={category.slug}
          >
            <CategoryImage src={category.image} alt={category.name} />
            <CategoryName>{category.name}</CategoryName>
          </CategoryHeader>
        ))}
      </Dropdown>
    </DropdownContainer>
  );
};
